export const SIGN_UP = 'user/SIGN_UP';
export const VERIFY_EMAIL = 'user/VERIFY_EMAIL';
export const FORGOT_PASSWORD = 'user/FORGOT_PASSWORD';
export const RESET_PASSWORD = 'user/RESET_PASSWORD';
export const SIGN_IN = 'user/SIGN_IN';
export const GET_USER = 'user/GET_USER';
export const EDIT_USER = 'user/EDIT_USER';
export const CHANGE_AVATAR = 'user/CHANGE_AVATAR';
export const CHANGE_PASSWORD = 'user/CHANGE_PASSWORD';
export const LOG_OUT = 'user/LOG_OUT';

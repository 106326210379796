export const CREATE_LEARNING_PATH = 'learningPath/CREATE_LEARNING_PATH';
export const GET_LEARNING_PATHS = 'learningPath/GET_LEARNING_PATHS';
export const SET_CURRENT_PAGE = 'learningPath/SET_CURRENT_PAGE';
export const GET_USER_LEARNING_PATH = 'learningPath/GET_USER_LEARNING_PATH';
export const UPDATE_USER_LEARNING_PATH = 'learningPath/UPDATE_USER_LEARNING_PATH';
export const SET_LEARNING_PATH = 'learningPath/SET_LEARNING_PATH';
export const RESET_LEARNING_PATH = 'learningPath/RESET_LEARNING_PATH';
export const DELETE_USER_LEARNING_PATH = 'learningPath/DELETE_USER_LEARNING_PATH';
export const CREATE_USER_LEARNING_PATH_ITEM = 'learningPath/CREATE_USER_LEARNING_PATH_ITEM';
export const EDIT_USER_LEARNING_PATH_ITEM = 'learningPath/EDIT_LEARNING_PATH_ITEM';
export const DELETE_USER_LEARNING_PATH_ITEM = 'learningPath/DELETE_USER_LEARNING_PATH_ITEM';
export const SET_EDITING_LEARNING_PATH_ITEM = 'learningPath/SET_EDITING_LEARNING_PATH_ITEM';
export const RESET_EDITING_LEARNING_PATH_ITEM = 'learningPath/RESET_EDITING_LEARNING_PATH_ITEM';
